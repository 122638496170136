<template>
  <div class="send-email">
    <div class="store-info">
      <b-card-title class="mb-3 font-weight-bold" title-tag="h2">
        {{ $t("g.reset_password") }}
      </b-card-title>
      <p class="mb-3">{{ $t("g.adding_email") }}</p>
    </div>

    <validation-observer ref="emailCodeValidation">
      <b-form class="forget-password-form" @submit.prevent>
        <b-form-group :label="$t('g.email')" label-for="forget-password-email">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="forget-password-email"
              v-model="email"
              :state="errors.length > 0 ? false : null"
              name="forget-password-email"
              :placeholder="$t('g.email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          @click="emailCodeValidationForm"
          class="primary-btn mt-3"
          :disabled="isPending"
        >
          <div v-if="isPending" class="loading_" data-loading="light" />
          <span v-else>{{ $t("g.send_code") }}</span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BLink,
  BCardTitle,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { email, required } from "@core/utils/validations/validations";

export default {
  name: "SendEmailCode",
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BLink,
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
    BCardTitle,
  },
  data() {
    return {
      email: "",
      isPending: false,
    };
  },
  methods: {
    emailCodeValidationForm() {
      this.$refs.emailCodeValidation.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = new FormData();
          formData.append("email", this.email);
          this.$http
            .post("auth/check-email", formData)
            .then((res) => {
              if (res.status === 200) {
                this.$emit("changeFirstStageData", this.email);
                toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t("g.verifyCodeSent"),
                    icon: "CheckCircleIcon",
                    variant: "success",
                  },
                });
              }
            })
            .catch((err) => {
              toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: this.$t("g.verifyCodeError"),
                },
              });
            })
            .finally(() => {
              this.isPending = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" src="./_sendEmailCode.scss" scoped />
